//  How to Use Javascript via Webpacker in Rails 6 (and Flatpickr) 
// https://www.youtube.com/watch?v=Hz8d6zPDSrk
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/material_orange.css'
document.addEventListener('turbolinks:load', () => {
  flatpickr('.flatpickr', {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'Y-m-d'
  })
} );