$(function() {
  // loadMathJax();
  $(document).on('turbolinks:load', loadMathJax);
});

var loadMathJax = function() {
  // window.MathJax = null;
  // console.log('reload')
  MathJax = {
      tex: {
        inlineMath: [['$', '$'], ['\\(', '\\)']]
      }
    };
  $.getScript("https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js", () => {} );
  //     window.MathJax.Hub.Config(
  //         {
  //             showMathMenu: false,
  //             TeX: {
  //                 extensions: ["[mhchem]/mhchem.js"]
  //             }
  //         } )
  //   );
  // console.log('loaded') );
};